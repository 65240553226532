<template>
  <div style="overflow:initial;">
    <!-- เมนูทางขวา -->
    <v-navigation-drawer
      v-model="drawer"
      floating
      :color="color.theme"
      :dark="color.darkTheme"
      app
      ><div class="text-center mt-2">
        <img
          v-if="triggle_feature_logo() === true"
          :src="logo_default"
          width="40%"
          height="40%"
        />
        <img
          v-else
          src="@/assets/logo_onebox-horizontal-white.png"
          width="38%"
          height="38%"
        />
      </div>
      <v-list dense nav>
        <!-- <v-divider></v-divider> -->
        <!-- อัปโหลดไฟล์ -->
        <v-menu> </v-menu>
        <v-divider></v-divider>
        <!-- กดเปลี่ยนบริษัท -->
        <div class="pa-2 text-center">
          <v-avatar width="100">{{ $t("admin.adminheader") }}</v-avatar>
        </div>
        <v-divider></v-divider>

        <!-- <div class="pa-2">
          <v-autocomplete
            prepend-inner-icon="work"
            v-model="choosetypeuser"
            @change="changestorage_sidebar(choosetypeuser)"
            :items="typeuser"
            :item-text="$t('default') === 'en' ? 'nameen': 'nameth'"
            hide-details
            outlined
            dense
            return-object
            :item-color="color.theme"
          >
            <template :color="color.theme" v-slot:item="{ item }">
              <span v-if="$t('default') === 'en'" style="font-size: 14px; " >{{ item.nameen }}</span>
              <span v-else style="font-size: 14px; " >{{ item.nameth }}</span>
            </template>
            <template :color="color.theme" v-slot:selection="{ item }">
              <span v-if="$t('default') === 'en'" style="font-size: 13px; " >{{ item.nameen }}</span>
              <span v-else style="font-size: 13px; "  >{{ item.nameth }}</span>
            </template>
          </v-autocomplete>
        </div> -->
        <!-- <v-divider></v-divider> -->
        <!-- เปลี่ยนไปหน้าอื่นๆ -->
        <template
          v-for="item in listmenu()"
          :v-if="
            this.dataAccountId === file.account_id &&
              file.file_permission.edit === 'True' &&
                file.editing_status === 'N'
          "
          :disabled="disableByPackage"
        >
          <v-row
            :color="color.theme"
            v-if="item.heading"
            :key="item.heading"
            align="center"
          >
            <v-col cols="6">
              <v-subheader v-if="item.heading">{{ item.heading }}</v-subheader>
            </v-col>
            <v-col cols="6" class="text-center">
              <a href="#!" class="body-2 black--text">EDIT</a>
            </v-col>
          </v-row>
          <v-list-group
            color="white"
            v-else-if="item.children"
            :key="item.text"
            v-model="item.model"
            prepend-icon
            :append-icon="item.model ? item.icon : item['icon-alt']"
          >
            <template v-slot:activator>
              <v-list-item class="pa-0">
                <v-list-item-content>
                  <v-list-item-title>{{ $t(item.text) }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <v-list-item
              v-for="(child, i) in item.children"
              :key="i"
              link
              @click="
                child.disable == true ? '' : tolink(child.link, child.text)
              "
              :disabled="child.disable"
            >
              <v-list-item-action>
                <v-icon>{{ child.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title class="pb-1 pt-1">{{
                  $t(child.text)
                }}</v-list-item-title>
                <v-expand-transition v-if="child.disable == true">
                  <v-overlay
                    absolute
                    color="grey lighten-1"
                    :value="disableByPackage"
                  >
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on">mdi-lock</v-icon>
                      </template>
                      <span>{{ $t("balloon.lock_function") }}</span>
                    </v-tooltip>
                  </v-overlay>
                </v-expand-transition>
              </v-list-item-content>
              <!-- <v-list-item-action v-if="child.icon">
                  <v-icon></v-icon>
              </v-list-item-action>-->
            </v-list-item>
          </v-list-group>
          <v-list-item
            v-else
            :to="item.disable == true ? '' : item.link"
            :disabled="item.disable"
            :key="item.text"
          >
            <v-list-item-content>
              <v-list-item-title class="pb-1 pt-1">{{
                $t(item.text)
              }}</v-list-item-title>
              <v-expand-transition v-if="item.disable == true">
                <v-overlay
                  absolute
                  color="grey lighten-1"
                  :value="disableByPackage"
                >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on">mdi-lock</v-icon>
                    </template>
                    <span>{{ $t("balloon.lock_function") }}</span>
                  </v-tooltip>
                </v-overlay>
              </v-expand-transition>
            </v-list-item-content>
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
          </v-list-item>
        </template>
      </v-list>
      <!-- <v-divider></v-divider> -->
      <!-- เปอรเซ็น storage -->
      <template v-slot:append>
        <div></div>
      </template>
    </v-navigation-drawer>

    <!-- เมนูข้างบน -->
    <v-app-bar class="elevation-0" outlined app color="background">
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <!-- search -->
      <!-- <v-row class="ma-5" v-if="quicksearch === true">
        <v-col cols="12" sm="6" lg="6">
          <v-text-field
            v-if="imageHeight >= 400"
            v-model="search"
            :label="$t('quicksearch')"
            append-icon="search"
            dense
            single-line
            outlined
            clearable
            hide-details
            @input="searchdata()"
            :color="color.theme"
          ></v-text-field>
        </v-col>
      </v-row> -->
      <v-spacer></v-spacer>
      <v-btn
        @mouseover="show_drive_business = true"
        @mouseleave="show_drive_business = false"
        class="mr-2"
        rounded
        :color="color.theme"
        dark
        small
        @click="fn_back()"
      >
        {{ $t("balloon.drive_business") }}
        <v-badge
          v-if="$t('default') === 'th'"
          style="z-index:10000;bottom:-45px;left:-60px;"
          :value="show_drive_business"
          :content="$t('balloon.drive_business')"
          transition="slide-y-transition"
        ></v-badge>
        <v-badge
          v-else
          style="z-index:10000;bottom:-45px;left:-95px;"
          :value="show_drive_business"
          :content="$t('balloon.drive_business')"
          transition="slide-y-transition"
        ></v-badge>
      </v-btn>
      <!-- <v-btn
          v-if ="role_level === 'True' && dataAccountActive.type !== 'Citizen'"
          @mouseover="show_admin = true"
          @mouseleave="show_admin = false"
          class="mr-2"
          fab
          text 
          small
          @click="fn_admin()"
                >
                <img src="@/assets/admin.png" alt="John" width="28px" />
                  <v-badge
                    v-if="$t('default') === 'th'"
                    style="z-index:10000;bottom:-45px;left:-33px;"
                    :value="show_admin"
                    :content="$t('balloon.admin')"
                    transition="slide-y-transition"
                  ></v-badge>
                  <v-badge
                    v-else
                    style="z-index:10000;bottom:-45px;left:-30px;"
                    :value="show_admin"
                    :content="$t('balloon.admin')"
                    transition="slide-y-transition"
                  ></v-badge>
                </v-btn> -->
      <!-- อัปโหลดไฟล์ -->
      <v-menu></v-menu>
      <v-divider inset vertical></v-divider>
      <!-- โปรไฟล์ -->
      <v-menu transition="slide-y-transition" bottom>
        <template v-slot:activator="{ on }">
          <v-btn text x-large v-on="on">
            <v-list-item-avatar v-if="dataCitizenProfile.picture == null">
              <img src="@/assets/user.png" alt="John" />
            </v-list-item-avatar>
            <v-list-item-avatar v-else>
              <img
                :src="'data:image/jpeg;base64,' + dataCitizenProfile.picture"
                alt="John"
              />
            </v-list-item-avatar>
            <div class="text-left" v-if="imageHeight >= 500">
              <b>{{ fullname }}</b>
              <br />
              <span style="font-size: 13px;">{{
                $store.getters.dataTypeusercurrent(
                  $store.state.account_active["type"],
                  $t("default")
                )
              }}</span>
            </div>
          </v-btn>
        </template>
        <v-card>
          <v-list nav>
            <v-list-item>
              <v-list-item-avatar v-if="dataCitizenProfile.picture == null">
                <img src="@/assets/user.png" alt="John" />
              </v-list-item-avatar>
              <v-list-item-avatar v-else>
                <img
                  :src="'data:image/jpeg;base64,' + dataCitizenProfile.picture"
                  alt="John"
                />
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{ fullname }}</v-list-item-title>
                <v-list-item-subtitle>{{
                  $store.getters.dataTypeusercurrent(
                    $store.state.account_active["type"],
                    $t("default")
                  )
                }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-avatar>
                <v-menu transition="slide-x-transition" left>
                  <template v-slot:activator="{ on }">
                    <v-btn fab text v-on="on" small>
                      <v-icon>mdi-palette</v-icon>
                    </v-btn>
                  </template>
                  <v-layout
                    :style="
                      $vuetify.theme.dark
                        ? 'background-color: black;'
                        : 'background-color: white;'
                    "
                    class="pa-1"
                  >
                    <v-flex>
                      <v-row no-gutters>
                        <v-subheader class="pa-0 pl-1">{{
                          $t("theme")
                        }}</v-subheader>
                      </v-row>
                      <v-row no-gutters>
                        <v-divider></v-divider>
                        <v-col
                          v-for="(item, n1) in color_1"
                          :key="n1"
                          class="pa-1"
                        >
                          <!-- <v-card class="pa-4"  outlined  :color="item.name" @click="changecolor(item.data)"></v-card>  -->
                          <v-avatar
                            :color="item.name"
                            size="36"
                            @click="changecolor(item.data, item.data_code)"
                            class="elevation-4"
                            style="cursor: pointer"
                            ><v-icon
                              :dark="color.darkTheme"
                              v-if="color.theme === item.name"
                              >done</v-icon
                            ></v-avatar
                          >
                        </v-col>
                      </v-row>
                      <v-row no-gutters>
                        <v-col
                          v-for="(item, n2) in color_2"
                          :key="n2"
                          class="pa-1"
                        >
                          <v-avatar
                            :color="item.name"
                            size="36"
                            @click="changecolor(item.data, item.data_code)"
                            class="elevation-4"
                            style="cursor: pointer"
                            ><v-icon
                              :dark="color.darkTheme"
                              v-if="color.theme === item.name"
                              >done</v-icon
                            ></v-avatar
                          >
                          <!-- <v-card class="pa-4"  outlined  :color="item.name" @click="changecolor(item.data)"></v-card> -->
                        </v-col>
                      </v-row>
                      <v-row no-gutters>
                        <v-col
                          v-for="(item, n3) in color_3"
                          :key="n3"
                          class="pa-1"
                        >
                          <v-avatar
                            :color="item.name"
                            size="36"
                            @click="changecolor(item.data, item.data_code)"
                            class="elevation-4"
                            style="cursor: pointer"
                            ><v-icon
                              :dark="color.darkTheme"
                              v-if="color.theme === item.name"
                              >done</v-icon
                            ></v-avatar
                          >
                          <!-- <v-card class="pa-4"  outlined  :color="item.name" @click="changecolor(item.data)"></v-card> -->
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-subheader
                          class="pa-0 pl-4 pointer"
                          @click="openColorpicker = true"
                          >{{ $t("toolbar.morecolor") }}</v-subheader
                        >
                      </v-row>
                    </v-flex>
                  </v-layout>
                </v-menu>
              </v-list-item-avatar>
            </v-list-item>
          </v-list>

          <v-divider></v-divider>
          <v-card-text class="pa-0">
            <v-list>
              <v-list-item
                v-for="(item, i) in listitem()"
                :key="i"
                link
                @click="linktomenu(item, choosetypeuser)"
              >
                <v-list-item-content>
                  <v-list-item-title>{{ $t(item.text) }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-action>
              </v-list-item>
            </v-list>
            <v-divider></v-divider>
            <v-list class="text-center">
              <v-list-item justify-center>
                <v-list-item-content>
                  <v-list-item-title>{{ version }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-menu>
      <!-- เปลี่ยนภาษา -->
      <v-menu transition="slide-y-transition" bottom>
        <template v-slot:activator="{ on }">
          <v-btn fab text v-on="on" small>
            <flag :iso="defaultflag" :squared="false" />
          </v-btn>
        </template>
        <v-list nav dense>
          <v-list-item
            v-for="(item, i) in language"
            :key="i"
            @click="changelang(item.data)"
          >
            <v-list-item-action>
              <flag :iso="item.flag" :squared="false" />
            </v-list-item-action>
            <v-list-item-title>{{ $t(item.name) }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <dialogprofile
      :show="openprofile"
      @closedialog="openprofile = false"
    ></dialogprofile>
    <colorpicker
      :show="openColorpicker"
      :setColor="setcolor"
      @close="closeDialog(), (openColorpicker = false)"
      @openColorpicker="openColorpicker = true"
      @closeDrag="$emit('closeDrag')"
      @openDrag="$emit('openDrag')"
    ></colorpicker>

    <!-- COOKIE TIMEOUT -->
    <div v-if="isShowDialogExpired">
      <dialogexpired 
      :showExpired="isShowDialogExpired" 
      :status="status_dialog"
      @logoutbotonebox="closedialogOtpexpired"
      @closecontinue="closediloagOtpcontinue"
      >
      </dialogexpired>
    </div>

    <!-- TOKEN -->
    <dialogshowtoken
      :isopen_token="opendialog_getToken"
      @closedialog="fn_close_token()"
      @closeDragMain="fn_close_drag()"
      @openDragMain="fn_open_drag()"
    >
    </dialogshowtoken>

  </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.js";
import VueCookies from "vue-cookies";
import CryptoJS from "crypto-js";

const dialogprofile = () => import("../optional/dialog-profile");
const colorpicker = () => import("../optional/dialog-colorpicker");
const dialogexpired = () => import("../optional/dialog-boxbotexpired.vue")
const dialogshowtoken = () => import("../optional/dialog-showToken.vue")
const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});

export default {
  props: ["quicksearch", "setcolor"],
  components: { dialogprofile, colorpicker, dialogexpired, dialogshowtoken }, // maitest },
  data: () => ({
    opendialog_getToken: false,
    status_dialog: "",
    interval_check_expired: "", // testmai
    testtoken_auth: "", // testmai
    isShowDialogExpired: false, // testmai
    token_share: "",
    show_drive_business: false,
    version: "",
    openUploadfiles: false,
    permission_denied_account: true,
    type: "hex",
    hex: "#FF00FF",
    openprofile: false,
    search: "",
    counter: 0,
    defaultaccount: "ผู้ใช้งานทั่วไป",
    defaultflag: "",
    typeuser: [],
    choosetypeuser: {},
    language: [
      {
        flag: "us",
        name: "languages.english",
        data: "en",
      },
      {
        flag: "th",
        name: "languages.thai",
        data: "th",
      },
    ],
    dialog: false,
    drawer: null,
    tabs: 2,
    openColorpicker: false,
    color_1: [
      {
        name: "#B71C1C",
        data: "#B71C1C",
        data_code: "hsla(0, 73%, 99%, 1)",
      },
      {
        name: "#EF5F33",
        data: "#EF5F33",
        data_code: "hsla(14, 85%, 99%, 1)",
      },
      {
        name: "#FFA000",
        data: "#FFA000",
        data_code: "hsla(38, 100%, 99%, 1)",
      },
      {
        name: "#228B22",
        data: "#228B22",
        data_code: "hsla(120, 61%, 99%, 1)",
      },
    ],
    color_2: [
      {
        name: "#7CB342",
        data: "#7CB342",
        data_code: "hsla(89, 46%, 99%, 1)",
      },
      {
        name: "#174966",
        data: "#174966",
        data_code: "hsla(202, 63%, 97%, 1)",
      },
      {
        name: "#1E90FF",
        data: "#1E90FF",
        data_code: "hsla(210, 100%, 99%, 1)",
      },
      {
        name: "#622D90",
        data: "#622D90",
        data_code: "hsla(272, 52%, 99%, 1)",
      },
    ],
    color_3: [
      {
        name: "#F17B9B",
        data: "#F17B9B",
        data_code: "hsla(344, 81%, 99%, 1)",
      },
      {
        name: "#6D4C41",
        data: "#6D4C41",
        data_code: "hsla(15, 25%, 99%, 1)",
      },
      {
        name: "#696969",
        data: "#696969",
        data_code: "hsla(0, 0%, 99%, 1)",
      },
      {
        name: "#1976D2",
        data: "#1976D2",
        data_code: "hsla(210, 79%, 99%, 1)",
      },
    ],
  }),
  created() {
    this.changelang(localStorage.getItem("lang"));
    this.changecolor(
      localStorage.getItem("color"),
      localStorage.getItem("color_code_temp")
    );
    this.dataTypeUser();
    // window.addEventListener('beforeunload', this.someMethod)
  },
  computed: {
    ...mapState(["color", "status_message", "triggle_timeout_otp", "feature_logo", "logo_default", "feature_inputmore"]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters({
      dataBusinessProfile: "dataBusinessProfile",
      dataAccesstoken: "dataAccesstoken",
    }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
      "dataStorageUsage",
      "dataStorageMax",
      "dataCheckPackage",
    ]),

    checkroutername() {
      return this.$router.app["_route"]["name"] || "";
    },
    fullname() {
      if (this.$t("default") === "th") {
        return (
          this.$store.getters.dataCitizenProfile["first_name_th"] +
            " " +
            this.$store.getters.dataCitizenProfile["last_name_th"] || ""
        );
      } else {
        if (
          this.$store.getters.dataCitizenProfile.hasOwnProperty(
            "first_name_eng"
          ) === true
        ) {
          return (
            this.$store.getters.dataCitizenProfile["first_name_eng"] +
              " " +
              this.$store.getters.dataCitizenProfile["last_name_eng"] || ""
          );
        } else {
          return (
            this.$store.getters.dataCitizenProfile["first_name_th"] +
              " " +
              this.$store.getters.dataCitizenProfile["last_name_th"] || ""
          );
        }
      }
    },
    imageHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
    sortColor() {
      for (let i = 0; i < this.colors.length; i++) {
        return this.colors[i];
      }
    },
    colorset: {
      get() {
        return this[this.type];
      },
      set(v) {
        this[this.type] = v;
      },
    },
    showColor() {
      if (typeof this.colorset === "string") return this.colorset;

      return JSON.stringify(
        Object.keys(this.colorset).reduce((colorset, key) => {
          colorset[key] = Number(this.colorset[key].toFixed(2));
          return colorset;
        }, {}),
        null,
        2
      );
    },
  },
  methods: {
    fn_close_drag () {
      console.log("No Special")
    },
    fn_open_drag () {
      console.log("No Special")
    },
    fn_close_token () {
      this.opendialog_getToken = false
    },
    diff_minutes (dt2, dt1) { // ใช้งานอยู่
      var diff =(dt2 - dt1) / 1000;
      diff /= 60;
      return Math.abs(Math.round(diff))
    },

    encryptdata (raw_data) { // ใช้งานอยู่
      let enc = CryptoJS.AES.encrypt(window.btoa(raw_data), 
      CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY),{ mode: CryptoJS.mode.ECB, }).toString()
      return enc
    },

    decryptdata (data) { // ใช้งานอยู่
      try{
      var data_decrypt = CryptoJS.AES.decrypt(data, CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY), { mode: CryptoJS.mode.ECB, });
      data_decrypt = window.atob(data_decrypt.toString(CryptoJS.enc.Utf8)) 
      return data_decrypt
      } catch(err) {
        return "error"
      }
    },

    async starttriggle_auth_token () { // ปิดการใช้งานอยู่
      if( VueCookies.get("username") !== null && VueCookies.get("username") !== "" && 
      VueCookies.get("username") !== undefined){
        let username_show = this.decryptdata(VueCookies.get("username"))
        if(username_show === process.env.VUE_APP_ACCOUNT_BOT){
          if(VueCookies.isKey("checklogin") === false){ // ไม่ได้เลือก จดจำฉัน
            this.interval_check_expired = setInterval(() => {
            this.triggle_expired_token_botonbox_temporary ()
            }, 6000)
          } else {
            this.interval_check_expired = setInterval(() => { //เลือกจดจำฉัน
            this.triggle_expired_token_botonbox()
            }, 6000)
          }
        }
      }
      
    },

    async triggle_otp_timeout () { // ใช้งานอยู่
      if(this.triggle_timeout_otp === true){
        this.isShowDialogExpired = true
        this.status_dialog = this.status_message
      }
    },

    triggle_expired_token_botonbox_temporary (status) { // ปิดการใ้ชงาน
      var check_isExist_login_logindatetime = VueCookies.isKey("login_datetime")
      var check_logindatetime = this.decryptdata(VueCookies.get("login_datetime"))
      if(check_isExist_login_logindatetime === true  && check_logindatetime !== "error" && VueCookies.isKey("data_id")) {
        var timeUsed = this.diff_minutes(new Date().getTime(), new Date(check_logindatetime).getTime())
        if(timeUsed === 10 && VueCookies.get("continue_otp") === "false"){
            this.status_dialog = "WARNING"
        } else if (timeUsed  <=  0) { //timeused === เวลาที่กำหนด
            this.status_dialog = "TIMEOUT"
        }
        this.isShowDialogExpired = true
        clearInterval(this.interval_check_expired)
        
      } else {
        clearInterval(this.interval_check_expired)
        this.$router.push({ path: "/logout" });
      }

    },

    triggle_expired_token_botonbox (status) { // ปิดการใช้งาน
      var check_isExist_login_logindatetime = VueCookies.isKey("login_datetime")
      var check_logindatetime = this.decryptdata(VueCookies.get("login_datetime"))
      if(check_isExist_login_logindatetime === true && check_logindatetime !== "error" && VueCookies.isKey("data_id")) {
        if( VueCookies.isKey("token_auth") === true  ) {
          var dateLogin = CryptoJS.AES.decrypt(VueCookies.get("login_datetime"), CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY), { mode: CryptoJS.mode.ECB, });
          dateLogin = window.atob(dateLogin.toString(CryptoJS.enc.Utf8))
          var timeUsed = this.diff_minutes(new Date().getTime(), new Date(dateLogin).getTime())
          if(timeUsed === 10 && VueCookies.get("continue_otp") === "false"){ // ใช้เวลาไปแล้วตามที่กำหนด
            this.isShowDialogExpired = true
              this.status_dialog = "WARNING"
              clearInterval(this.interval_check_expired)
            }
        }  else {
          this.isShowDialogExpired = true
          this.status_dialog = "TIMEOUT"
          clearInterval(this.interval_check_expired)
        }
      } else {
        clearInterval(this.interval_check_expired)
        this.$router.push({ path: "/logout" });
      }
    },

    triggle_feature_logo () {
      try{
      if(this.dataAccountActive["type"] === "Business"){
        if(this.feature_logo){
          if(this.logo_default === "" || this.logo_default === 'default'){ // แสดงว่ายังไม่มีการเปลี่ยน logo หรือ ตั้งค่า logo เป็น default
            return false
          } else {
            return true
          }
        } else {
          return false
        }
      } else {
        console.log("HEREEER")
        return false
      }  
      }
      catch (err) {
        return false
      }
      
    },


    async logout_bot_onebox () {
      let payload = {
        data_id:  VueCookies.get("data_id")
      }
      return await this.axios
      .post(process.env.VUE_APP_SERVICE_AUTHORIZE_CITIZEN + "/api/v1/logout/otp", payload)
      .then((response) => {
        return new Promise((resolve, reject) => {
          if (response.data.status === "OK") {
            setTimeout(() => {
              resolve({ status: "OK", message: "Verified" });
            }, 500);
          } else {
            setTimeout(() => {
              resolve({ status: "INVALID OTP", message: "Invalid OTP provided" });
            }, 500);
          }
        });
      })
      .catch((err) => {})
    },
    async closedialogOtpexpired () {
      await this.logout_bot_onebox ()
      .then((res) => {
        if(res.status === 'OK'){
          this.$store.dispatch("triggle_otp_botonebox_manage", {status: "", show: false}) // clear vuex
          this.$router.push({ path: "/logout" });
        }
      })
    },
    closediloagOtpcontinue () {
      this.$store.dispatch("triggle_otp_botonebox_manage", {status: "", show: false}) // clear vuex
      this.isShowDialogExpired = false
      //this.starttriggle_auth_token()
    },

    gotoimportemployee() {
      // const token = localStorage.getItem("token");
      // เก่า const token = VueCookies.get("token") ? VueCookies.get("token") : localStorage.getItem("token") ? localStorage.getItem("token") : sessionStorage.getItem("token") ;
      const token = VueCookies.get("token") ? VueCookies.get("token") : sessionStorage.getItem("token") ;
      this.token_share = token ? window.atob(token) : "";
      //   let token = VueCookies.get("token");
      // let tokenOneid = "Bearer" + " " + this.token_share;
      this.axios
        .get(process.env.VUE_APP_ONEID + "/api/v2/service/shared-token", {
          headers: {
            Authorization: `Bearer ${this.token_share}`,
          },
        })
        .then((response) => {
          console.log(response);
          if (response.data.result === "Success") {
            console.log("process.env.NODE_ENV ",process.env );
            // let url_toid;
            if (process.env.VUE_APP_ENVIRONMENT === "PRODUCTION") {
              window.open("https://business.one.th/redirect-share-token?share_token=" +
                response.data.data.shared_token +
                "&excel_biz_id=" +
                this.dataAccountActive.business_info.id);
              // url_toid =
              //   "https://business.one.th/redirect-share-token?share_token=" +
              //   response.data.data.shared_token +
              //   "&excel_biz_id=" +
              //   this.dataAccountActive.business_info.id;
            } else {
              window.open("https://uat-business.one.th/redirect-share-token?share_token=" +
                response.data.data.shared_token +
                "&excel_biz_id=" +
                this.dataAccountActive.business_info.id);
              // url_toid =
              //   "https://uat-business.one.th/redirect-share-token?share_token=" +
              //   response.data.data.shared_token +
              //   "&excel_biz_id=" +
              //   this.dataAccountActive.business_info.id;
            }
            // window.open(url_toid, "_blank");
            // window.open(url_toid, "_self");
            // window.open(url_toid);
            // console.log("url_toid", url_toid);
          }
        })
        .catch((error) => {
          this.createprogress = false;
          Toast.fire({
            icon: "error",
            title: error,
          });
          console.log(error);
        });
    },
    fn_back() {
      this.gotoMydrive();
    },
    tolink(parameter, text) {
      if (this.imageHeight <= 500) {
        this.drawer = !this.drawer;
      }
      if (text === "admintoolbar.managefile") {
        this.$router.push({ path: parameter, query: { type: 1 } });
      } else if (text === "admintoolbar.managestorage") {
        this.$router.push({ path: parameter, query: { type: 2 } });
      } else if (text === "admintoolbar.manageinbox") {
        this.$router.push({ path: parameter, query: { type: 3 } });
      } else if (text === "admintoolbar.manageoutbox") {
        this.$router.push({ path: parameter, query: { type: 4 } });
      } else if (text === "admintoolbar.managefeature") {
        this.$router.push({ path: parameter, query: { type: 5 } });
      } else if (text === "admintoolbar.manageemployee") {
        this.gotoimportemployee();
        // this.$router.push(parameter);
      } else {
        this.$router.push(parameter);
      }
    },

    linktomenu(parameter, choosetypeuser) {
      console.log(parameter);
      if (parameter.key === "profile") {
        this.openpro();
      } else if (parameter.key === "mydrive_bu") {
        this.gotoMydrive();
        // }else if(parameter.key === 'เพิ่มพนักงาน'){
        //   this.gotoimportemployee();
      } else if (parameter.key === "token") {
        this.opendialog_getToken = true
      } else {
        let username_show = this.decryptdata(VueCookies.get("username"))
        if(username_show === process.env.VUE_APP_ACCOUNT_BOT){
          this.closedialogOtpexpired()
        } else {
          this.$store
          .dispatch("change_default_logo", "")
          .then((msg) => {
            if(msg.status === 'OK'){
              this.$router.push({ path: "/logout" });
            }
          })
          .catch(()=>{ // เผื่อๆ
            this.$router.push({ path: "/logout" });
          })
        }
      }
    },
    listitem() {
      let _items = [];
      _items = [
        {
          active: true,

          icon: "mdi-domain",

          text: "toolbar.mydrive_bu",
          link:
            "/directory/" +
            this.dataAccountActive["business_info"]["my_folder_id"],
          disable: false,
          key: "mydrive_bu",
        },

        {
          active: true,
          // icon: "settings",
          icon: "mdi-account-circle",
          text: "Personal",
          link: "",
          disable: false,
          key: "profile",
        },
        {
          active: true,
          icon: "mdi-key",
          text: "Onebox Token",
          link: "",
          disable: false,
          key: "token",
        },
        {
          active: true,
          icon: "mdi-logout",
          text: "logout",
          link: "",
          disable: false,
          key: "logout",
        },
      ];

      return _items;
    },
    listmenu() {
      let _items = [];
      _items = [
        {
          active: true,
          icon: "mdi-monitor-dashboard",
          text: "admintoolbar.dashboard",
          link: "/onebox-system-admin-dashboard",
          // link: "/system-admin-dashboard",
          disable: !this.dataCheckPackage.package,
        },
        {
          active: true,
          prependicon: "mdi-chevron-up",
          icon: "mdi-chevron-down",
          "icon-alt": "mdi-chevron-down",
          text: "admintoolbar.manageheader",
          model: false,
          children: [
            {
              //active: true,
              icon: "mdi-image-edit",
              text: "logo_feature.manage_logo",
              link: "/onebox-system-admin-managelogo",
              // link: "/system-admin-managefile",
              disable: false,
              type: 6,
            },
            {
              //active: true,
              icon: "mdi-folder-edit",
              text: "admintoolbar.managefile",
              link: "/onebox-system-admin-managefile",
              // link: "/system-admin-managefile",
              disable: false,
              type: 1,
            },
            {
              //active: true,
              icon: "mdi-server",
              text: "admintoolbar.managestorage",
              link: "/onebox-system-admin-managestorage",
              // link: "/system-admin-managestorage",
              disable: !this.dataCheckPackage.package,
              type: 2,
            },
            // {
            //   //active: true,
            //   icon: "mdi-inbox-arrow-down-outline",
            //   text: "admintoolbar.manageinbox",
            //   link: "/onebox-system-admin-manageinbox",
            //   // link: "/system-admin-manageinbox",
            //   disable: false,
            //   type: 3,
            // },
            // {
            //   // active: true,
            //   icon: "mdi-inbox-arrow-up-outline",
            //   text: "admintoolbar.manageoutbox",
            //   link: "/onebox-system-admin-manageoutbox",
            //   // link: "/system-admin-manageoutbox",
            //   disable: false,
            //   type: 4,
            // },
            {
              //active: true,
              icon: "mdi-monitor-edit",
              text: "admintoolbar.managefeature",
              link: "/onebox-system-admin-managefeature",
              // link: "/system-admin-managefeature",
              disable: false,
              type: 5,
            },
            {
              icon: "mdi-account-box-multiple",
              text: "admintoolbar.manageemployee",
              // route: "https://uat-business.one.th/redirect-share-token?share_token="+ this.token_share + "&excel_biz_id=" + this.dataAccountActive.business_info.id,
              disable: false,
            },
            // {
            //   //active: true,
            //   icon: "mdi-account-box-multiple",
            //   text: "admintoolbar.manageemployee",
            //   link: "/onebox-system-admin-manageemployee",
            //   link: "/system-admin-manageemployee",
            //   disable: false,
            //    type: 6,

            // },
            // จัดการแมพไดรฟ์
            // {
            //   icon: "mdi-network",
            //   text: "admintoolbar.managemapdrive",
            //   link: "/onebox-system-admin-managemapdrive",
            //   disable: false,
            //   type: 7,
            // },
          ],
        },

        {
          active: true,
          icon: "mdi-file-table-box-multiple",
          text: "admintoolbar.log",
          link: "/onebox-system-admin-log",
          // link: "/system-admin-log",
          disable: !this.dataCheckPackage.package,
        },
        {
          active: true,
          icon: "mdi-cloud",
          text: "admintoolbar.mydriveadmin",
          link: "/onebox-system-admin-mydrive",
          // link: "/system-admin-mydrive",
          disable: false,
        },
        {
          active: true,
          icon: "mdi-account-cog",
          text: "admintoolbar.setadmin",
          link: "/onebox-system-admin-setting",
          // link: "/system-admin-setting",
          disable: !this.dataCheckPackage.package,
        },
        {
          active: true,
          icon: "contact_mail",
          text: "admintoolbar.setemail",
          link: "/onebox-system-admin-setemail",
          // link: "/system-admin-setemail",
          disable: false,
        },
        {
          active: true,
          icon: "mdi-key",
          text: "admintoolbar.genkey",
          link: "/onebox-system-admin-generatekey",
          // link: "/system-admin-generatekey",
          disable: false,
        },
        {
          active: true,
          icon: "mdi-file-document-edit",
          // text: "admintoolbar.consent",
          text: "admintoolbar.setbusiness",
          link: "/onebox-system-admin-consent",
          // link: "/system-admin-consent",
          disable: false,
        },
        // {
        //   active: true,
        //   icon: "mdi-email-newsletter",
        //   text: "admintoolbar.recheckimap",
        //   link: "/onebox-system-admin-recheckimap",
        //   // link: "/system-admin-recheckimap",
        //   disable: false,
        // },
        {
          active: true,
          icon: "mdi-backup-restore",
          text: "admintoolbar.fileandfolderbackup",
          link: "/onebox-system-admin-fileandfolderbackup",
          // link: "/system-admin-fileandfolderbackup",
          disable: false,
        },
        {
          active: true,
          icon: "mdi-note-text",
          text: "admintoolbar.template_feature",
          link: "/onebox-system-admin-template",
          // link: "/system-admin-fileandfolderbackup",
          disable: false,
        },
      ];
      // COND ดักจับ feature logo
      if(!this.feature_logo){
        _items[1].children.splice(0, 1)
      }
      if(!this.feature_inputmore){
        _items.pop()
      }
      return _items;
    },
    closeDialog() {
      this.openColorpicker = false;
      this.openUploadfiles = false;
    },
    openfile() {
      //console.log("rot", this.$router.currentRoute.path);
      // if (this.$router.currentRoute.path === "/sharedwithme") {
      // Toast.fire({
      //   icon: "error",
      //   title: this.$t("uploadfile.donotshare")
      // });
      // } else {
      (this.openUploadfiles = true), this.$emit("callparentfolder");
      // }
    },
    openfolder() {
      // if (this.$router.currentRoute.path === "/sharedwithme") {
      // Toast.fire({
      //   icon: "error",
      //   title: this.$t("uploadfolder.donotshare")
      // });
      //} else {
      (this.openUploadfolder = true), this.$emit("callparentfolder");
      // }
    },
    openpro() {
      this.openprofile = true;
    },
    dataTypeUser() {
      console.log("test");
      if (this.$store.state.service !== "OneMail") {
        let _citizen = {
          type: "Citizen",
          data: [],
          nameth: "ผู้ใช้งานทั่วไป",
          nameen: "Personal",
          taxid: "",
        };

        this.typeuser.push(_citizen);
      } else {
        if (this.dataBusinessProfile.length === 0) {
          let _citizen = {
            type: "Citizen",
            data: [],
            nameth: "ผู้ใช้งานทั่วไป",
            nameen: "Personal",
            taxid: "",
          };
          this.typeuser.push(_citizen);
        }
      }
      let i = 0;
      for (i = 0; i < this.dataBusinessProfile.length; i++) {
        console.log("test2");

        if (this.$store.state.service !== "OneMail") {
          // console.log("test4");
          this.typeuser.push({
            type: "Business",
            data: this.dataBusinessProfile[i],
            nameth: this.dataBusinessProfile[i]["first_name_th"],
            nameen: this.dataBusinessProfile[i]["first_name_eng"],
            taxid: this.dataBusinessProfile[i]["id_card_num"],
            data_biz: this.dataBusinessProfile[i]["id"],
          });
        } else {
          // console.log("test5");
          this.typeuser.push({
            type: "Business",
            data: this.dataBusinessProfile[i],
            nameth: this.dataBusinessProfile[i]["first_name_th"],
            nameen: this.dataBusinessProfile[i]["first_name_eng"],
            taxid: this.dataBusinessProfile[i]["taxid"],
            data_biz: this.dataBusinessProfile[i]["id"],
          });
        }
      }
      // console.log("this.dataAccountActive[]",this.dataAccountActive["type"]);
      console.log("type", this.typeuser);

      if (this.dataAccountActive["type"] === "Business") {
        let type_business_index = this.typeuser.findIndex(
          (x) => x.taxid === this.dataAccountActive["taxid"]
        );
        this.choosetypeuser = this.typeuser[type_business_index];
      } else {
        console.log(this.$store.state.service);
        if (this.dataAccountActive["type"] === "Business") {
          let type_business_index = this.typeuser.findIndex(
            (x) => x.taxid === this.dataAccountActive["taxid"]
          );
          this.choosetypeuser = this.typeuser[type_business_index];
          this.checkbusiness = true;
          this.changestorage_sidebar(this.choosetypeuser);
        } else {
          this.choosetypeuser = {
            type: "Citizen",
            data: [],
            nameth: "ผู้ใช้งานทั่วไป",
            nameen: "Personal",
            data_biz: "",
          };
          this.changestorage_sidebar(this.choosetypeuser);
        }
        // if (this.$store.state.service !== "OneMail"){
        //   console.log("test");
        //  let type_business_index = this.typeuser.findIndex(
        //   x => x.taxid === this.dataBusinessProfile[0]["id_card_num"]
        //   );
        //   this.choosetypeuser = this.typeuser[type_business_index];
        // this.changestorage_sidebar(this.choosetypeuser);
        // }else{
        //   this.choosetypeuser = {
        //   type: "Citizen",
        //   data: [],
        //   nameth: "ผู้ใช้งานทั่วไป",
        //   nameen: "Personal"
        // };
        //   let type_business_index = this.typeuser.findIndex(
        //   x => x.taxid === this.dataBusinessProfile[0]["taxid"]
        // );
        // this.choosetypeuser = this.typeuser[type_business_index];
        // this.changestorage_sidebar(this.choosetypeuser);
        // }
        // this.$store.dispatch("switchaccount_citizen").then(msg => {
        //   //this.$emit("loadfile");
        //   //this.$emit("callstorage");
        //   this.$router.push({ name: "myfiles" });
        // });
        // this.choosetypeuser = {
        //   type: "Citizen",
        //   data: [],
        //   nameth: "ผู้ใช้งานทั่วไป",
        //   nameen: "Personal"
        // };
      }
    },

    changelang(parameter) {
      localStorage.setItem("lang", parameter);
      this.$i18n.locale = parameter;
      let dflag = this.language.findIndex((v) => v.data === parameter);
      this.defaultflag = this.language[dflag]["flag"];
    },
    changecolor(parameter, parameter_code) {
      console.log("color", parameter, parameter_code);
      // console.log(this.colors_.hsla["h"],this.colors_.hsla["s"],this.colors_.hsla["l"],this.colors_.hsla["a"]);
      //let color_code = "hsla("+String(this.colors_.hsla["h"])+","+String(this.colors_.hsla["s"]*100)+"%,"+String(99.99)+"%,"+String(this.colors_.hsla["a"]*100)+"%)"
      if (parameter === undefined || parameter === null) {
        console.log("if");
        localStorage.setItem("color", "#174966");
        localStorage.setItem("color_code", "hsla(202, 63%, 97%, 1)");
        localStorage.setItem("color_code_temp", "hsla(202, 63%, 97%, 1)");
      } else {
        localStorage.setItem("color_code_temp", parameter_code);
        localStorage.setItem(
          "color_code",
          this.$vuetify.theme.dark ? "#333333" : parameter_code
        );
        localStorage.setItem("color", parameter);
        //this.$store.dispatch("check_color",localStorage.getItem("color")).then(res => {});
      }
      this.$store
        .dispatch("check_color", localStorage.getItem("color"))
        .then((res) => {});
    },
    gotoMydrive() {
      let data_buffer = sessionStorage.getItem("biz_active");
      let data_biz = JSON.parse(data_buffer);
      console.log(data_biz);

      // this.$store.dispatch("switchaccount_business",data_biz).then(msg => {
      //     //this.$emit("loadfile");
      //     //this.$emit("callstorage");
      //     if(msg.status === "Business Access Granted"){
      console.log(this.dataAccountActive["business_info"]["my_folder_id"]);
      this.$router.push({
        path:
          "/directory/" +
          this.dataAccountActive["business_info"]["my_folder_id"],
      });
      //   }else{
      //     this.$router.push("logout");
      //   }

      // });
    },

    searchdata() {
      this.$emit("inputdata", this.search);
    },
    // changestorage_sidebar(_choosetypeuser) {
    //   console.log("_choosetypeuser",_choosetypeuser)
    //   let beforechoose = this.choosetypeuser;
    //   if (this.imageHeight <= 500) {
    //     this.drawer = !this.drawer;
    //   }
    //   if (_choosetypeuser.type === "Citizen") {
    //     this.$store.dispatch("switchaccount_citizen").then(msg => {
    //       this.$emit("loadfile");
    //       this.$emit("callstorage");

    //       this.$router.push({ name: "myfiles" });
    //     });
    //   } else {
    //     if (this.$store.state.service !== "OneMail") {
    //       let payload = {
    //         taxid: _choosetypeuser.data.id_card_num,
    //         accesstoken: this.dataAccesstoken
    //       };
    //       this.$store.dispatch("switchaccount_business", payload).then(msg => {
    //          console.log(msg);

    //         if (msg["status"] === "Business Access Denied") {
    //           if (msg["msg"] === "ER401: Authorization is wrong") {
    //             this.$router.push("logout");
    //           } else {
    //             if (this.dataAccountActive["type"] === "Business") {
    //               let type_business_index = this.typeuser.findIndex(
    //                 x => x.taxid === this.dataAccountActive["taxid"]
    //               );
    //               this.choosetypeuser = this.typeuser[type_business_index];
    //             } else {
    //               this.choosetypeuser = {
    //                 type: "Citizen",
    //                 data: [],
    //                 nameth: "ผู้ใช้งานทั่วไป",
    //                 nameen: "Personal"
    //               };
    //             }
    //             Toast.fire({ icon: "error", title: msg["msg"] });
    //             this.$emit("loadfile");
    //             this.$emit("callstorage");
    //             this.$router.push({ name: "myfiles" });
    //             // this.$store.dispatch("switchaccount_citizen").then(msg => {
    //             //   this.$emit("loadfile");
    //             //   this.$emit("callstorage");
    //             //   this.$router.push({ name: "myfiles" });
    //             // });
    //           }
    //         } else {
    //           this.choosetypeuser = _choosetypeuser;
    //           // console.log(msg);
    //           this.$emit("loadfile");
    //           this.$emit("callstorage");
    //           this.$emit("loaddata")
    //           this.$router.push({ name: "myfiles" });
    //         }
    //       });
    //     }else{
    //        this.$store.dispatch("switchaccount_businessonemail", _choosetypeuser).then(msg => {
    //           this.$emit("loadfile");
    //           this.$emit("callstorage");
    //           this.$router.push({ name: "myfiles" });
    //       })
    //     }
    //   }
    // },
    changestorage_sidebar(_choosetypeuser) {
      console.log(_choosetypeuser);
      let beforechoose = this.choosetypeuser;
      if (this.imageHeight <= 500) {
        this.drawer = !this.drawer;
      }
      if (_choosetypeuser.type === "Citizen") {
        this.checkbusiness = false;
        this.$store.dispatch("switchaccount_citizen").then((msg) => {
          this.$emit("loadfile");
          this.$emit("callstorage");
          this.$router.push({ name: "myfiles" });
        });
      } else {
        if (this.$store.state.service !== "OneMail") {
          this.checkbusiness = true;
          let payload = {
            taxid: _choosetypeuser.data.id_card_num,
            accesstoken: this.dataAccesstoken,
          };
          this.$store
            .dispatch("switchaccount_business", payload)
            .then((msg) => {
              if (msg["status"] === "Business Access Denied") {
                this.checkbusiness = false;
                console.log("msg", msg);

                if (msg["msg"] === "ER401: Authorization is wrong") {
                  this.$router.push("logout");
                } else {
                  if (this.dataAccountActive["type"] === "Business") {
                    let type_business_index = this.typeuser.findIndex(
                      (x) => x.taxid === this.dataAccountActive["taxid"]
                    );
                    this.choosetypeuser = this.typeuser[type_business_index];
                    this.checkbusiness = false;
                    console.log("feature", this.feature_status);
                  } else {
                    this.choosetypeuser = {
                      type: "Citizen",
                      data: [],
                      nameth: "ผู้ใช้งานทั่วไป",
                      nameen: "Personal",
                    };
                  }
                  Toast.fire({ icon: "error", title: msg["msg"] });
                  this.$emit("loadfile");
                  this.$emit("callstorage");
                  this.$router.push({ name: "myfiles" });
                  // this.$store.dispatch("switchaccount_citizen").then(msg => {
                  //   this.$emit("loadfile");
                  //   this.$emit("callstorage");
                  //   this.$router.push({ name: "myfiles" });
                  // });
                }
              } else {
                console.log("feature", this.feature_status);
                this.checkbusiness = true;
                this.choosetypeuser = _choosetypeuser;
                // console.log(msg);
                this.$emit("loadfile");
                this.$emit("callstorage");
                this.$router.push({ name: "myfiles" });
              }
            });
        } else {
          this.$store
            .dispatch("switchaccount_businessonemail", _choosetypeuser)
            .then((msg) => {
              this.$emit("loadfile");
              this.$emit("callstorage");
              this.$router.push({ name: "myfiles" });
            });
        }
      }
    },
    disableByPackage() {
      // return this.dataCheckPackage.type === 'Business' ? this.dataCheckPackage.package ? false : true : this.dataCheckPackage.package ? false : true
      return false;
    },
    // someMethod(event){
    //   this.$router.push({ name: "myfiles" })
    //   console.log("test");

    // }
  },
  mounted() {
    console.log("this.dataCheckPackage.package", this.dataCheckPackage.package);
    this.version = process.env.VUE_APP_SERVICE_VERSION;
    // window.addEventListener('unload', this.someMethod)
    const darkmode = localStorage.getItem("darkmode");
    //this.starttriggle_auth_token ()
    console.log("decrypdatat username ", this.decryptdata(VueCookies.get("username")))
    if(this.decryptdata(VueCookies.get("username")) === process.env.VUE_APP_ACCOUNT_BOT ){
      this.triggle_otp_timeout ()
    }
    if (darkmode) {
      if (darkmode === "true") {
        this.$vuetify.theme.dark = true;
      } else {
        this.$vuetify.theme.dark = false;
      }
    } else if (
      window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches
    ) {
      this.$vuetify.theme.dark = true;
      localStorage.setItem("darkmode", this.$vuetify.theme.dark.toString());
    }
    this.changecolor(
      localStorage.getItem("color"),
      localStorage.getItem("color_code_temp")
    );
  },
  beforeDestroy () {
    clearInterval(this.interval_check_expired)
  }
  // beforeDestroy(){
  //   window.removeEventListener('unload', this.someMethod)
  // }
};
</script>
<style></style>
